class Video {
  clickPlay = (event) => {
    event.target.parentNode.classList.add(this.classes.playing);
    const videoEl = event.target.nextElementSibling;
    if (videoEl) {
      videoEl.play();
    }
  };

  constructor() {
    this.selectors = {
      play: '.js-play-video',
    };

    this.DOM = {
      plays: document.querySelectorAll(this.selectors.play),
    };

    if (!this.DOM.plays) {
      return;
    }

    this.classes = {
      playing: 'playing',
    };

    this.init();
  }

  init() {
    this.DOM.plays.forEach((play) => {
      play.addEventListener('click', this.clickPlay);
    });
  }
}

export default new Video();
