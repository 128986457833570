import Utilities from './Utilities';

const contactFormScroll = () => {
  const button = document.querySelector('.js-solution-contact-btn');

  if (button) {
    button.addEventListener('click', (e) => {
      const link = e.target.getAttribute('href');

      if (link.substr(0, 1) === '#') {
        e.preventDefault();
        const form = document.querySelector(`${link}`);

        Utilities.smoothScrollToElement(form, 20);
      }
    });
  }
};

export default contactFormScroll();
