/* global wp */

class LoadMore {
  loadMore = (event, btn) => {
    event.preventDefault();
    const params = new URL(document.location).searchParams;
    const search = params.get('s');
    let offset = parseInt(btn.dataset.offset, 10);
    const { template } = btn.dataset;
    const args = {
      offset,
      template,
    };
    if (search) {
      args.s = search;
    }
    wp.ajax.post('load_more', args).done((response) => {
      const container = document.querySelector(btn.dataset.container);
      if (container) {
        container.innerHTML = response;
        offset += offset;
        btn.setAttribute('data-offset', offset);
        btn.blur();
        if (offset >= parseInt(btn.dataset.posts, 10)) {
          btn.remove();
        }
      }
    });
  };

  constructor() {
    this.selectors = {
      btn: '.js-load-more',
    };

    this.DOM = {
      btns: document.querySelectorAll(this.selectors.btn),
    };

    if (!this.DOM.btns) {
      return;
    }

    this.init();
  }

  init() {
    this.DOM.btns.forEach((btn) => {
      btn.addEventListener('click', (event) => this.loadMore(event, btn));
    });
  }
}

export default new LoadMore();
