import SimpleParallax from 'simple-parallax-js';

class Parallax {
  constructor() {
    this.parallaxes = [];
    this.selectors = {
      sizer: '.js-parallax-sizer',
      element: '.js-parallax',
    };

    this.DOM = {
      sizers: document.querySelectorAll(this.selectors.sizer),
      elements: document.querySelectorAll(this.selectors.element),
    };

    if (!this.DOM.elements) {
      return;
    }

    this.initSizers();
    this.init();

    window.addEventListener('resize', () => {
      this.initSizers();
    });
  }

  initSizers() {
    this.DOM.sizers.forEach((sizer, key) => {
      const s = sizer;
      if (window.outerWidth >= 1024) {
        s.style.height = sizer.dataset.height;
      } else {
        const img = this.DOM.elements[key].querySelector('img');
        if (img) {
          s.style.height = `${img.height}px`;
        }
      }
    });
  }

  init() {
    this.DOM.elements.forEach((element, key) => {
      const options = {
        delay: 0,
        orientation: 'up',
        scale: 1.75,
        overflow: true,
      };
      if (key === 0) {
        options.scale = 0.5;
      } else if (key === 2) {
        options.orientation = 'down';
        options.scale = 2.75;
      }
      /* eslint-disable no-new */
      this.parallaxes.push(new SimpleParallax(element, options));
    });
  }
}

export default new Parallax();
