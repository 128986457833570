import Utilities from './Utilities';

class MainNav {
  onMenuItemHover = (e) => {
    const link = e.currentTarget;
    const item = link.parentElement;
    const dropdown = item.querySelector(this.selectors.dropdown);

    this.closeActiveDropdowns();

    if (dropdown) {
      link.setAttribute('aria-expanded', true);
      dropdown.classList.add(this.classes.open);
    }
  };

  onMenuItemHoverOut = () => {
    this.closeActiveDropdowns();
  };

  onToggleMainNav = () => {
    this.isMobileNavOpen = !this.isMobileNavOpen;

    this.DOM.container.classList.toggle(
      this.classes.open,
      this.isMobileNavOpen,
    );

    this.DOM.toggler.classList.toggle(this.classes.open, this.isMobileNavOpen);
    this.DOM.toggler.setAttribute('aria-expanded', this.isMobileNavOpen);
  };

  onBreakpointChange = () => {
    this.closeActiveDropdowns();
  };

  onScroll = () => {
    this.DOM.header.classList.toggle(
      this.classes.scrolled,
      window.scrollY > this.headerScrollThreshold,
    );
  };

  constructor() {
    this.selectors = {
      container: '.js-main-nav-wrapper',
      nav: '.js-main-nav',
      item: '.js-main-nav-item',
      link: '.js-main-nav-link',
      dropdown: '.js-main-nav-dropdown',
      toggler: '.js-main-nav-toggler',
      header: '.js-header',
    };

    this.DOM = {
      container: document.querySelector(this.selectors.container),
      nav: document.querySelector(this.selectors.nav),
      links: document.querySelectorAll(this.selectors.link),
      items: document.querySelectorAll(this.selectors.item),
      dropdowns: document.querySelectorAll(this.selectors.dropdown),
      toggler: document.querySelector(this.selectors.toggler),
      header: document.querySelector(this.selectors.header),
    };

    if (!this.DOM.nav) {
      return;
    }

    this.classes = {
      open: 'is-open',
      edge: 'is-edge',
      scrolled: 'is-scrolled',
      sticky: 'is-sticky',
    };

    this.isMobileNavOpen = false;
    this.breakpoint = window.matchMedia('(min-width: 1024px)');
    this.isMobile = !this.breakpoint.matches;
    this.headerScrollThreshold = 150;

    this.setDropdownsPosition();

    this.toggleDropdown();
    this.mobileNavToggle();

    if (this.DOM.header.classList.contains(this.classes.sticky)) {
      document.addEventListener('scroll', this.onScroll);
      document.addEventListener('DOMContentLoaded', this.onScroll);
    }

    Utilities.debounce(this.setDropdownsPosition.bind(this), 500);

    if (typeof this.breakpoint.addEventListener === 'undefined') {
      this.breakpoint.addListener(this.onBreakpointChange);
    } else {
      this.breakpoint.addEventListener('change', this.onBreakpointChange);
    }
  }

  toggleDropdown() {
    this.DOM.links.forEach((link) => {
      link.addEventListener('mouseenter', this.onMenuItemHover);
      link.addEventListener('focus', this.onMenuItemHover);
    });

    this.DOM.items.forEach((item) => {
      item.addEventListener('mouseleave', this.onMenuItemHoverOut);
    });
  }

  mobileNavToggle() {
    this.DOM.toggler.addEventListener('click', this.onToggleMainNav);
  }

  setDropdownsPosition() {
    if (!this.DOM.dropdowns.length) {
      return;
    }

    this.DOM.dropdowns.forEach((dropdown) => {
      dropdown.classList.remove(this.classes.edge);

      const d = dropdown.getBoundingClientRect();
      const isEdge = window.innerWidth - d.left - d.width < 30;

      dropdown.classList.toggle(this.classes.edge, isEdge);
    });
  }

  closeActiveDropdowns() {
    const activeDropdowns = document.querySelectorAll(
      `${this.selectors.dropdown}.${this.classes.open}`,
    );

    if (!activeDropdowns.length) {
      return;
    }

    activeDropdowns.forEach((dropdown) => {
      const item = dropdown.parentElement;
      const link = item.querySelector(this.selectors.link);

      link.setAttribute('aria-expanded', false);
      dropdown.classList.remove(this.classes.open);
    });
  }
}

export default new MainNav();
