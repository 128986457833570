class Utilities {
  debounce = (fn, delay = 500) => {
    let debounce = null;
    window.addEventListener('resize', () => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        fn();
      }, delay);
    });
  };

  smoothScrollToElement = (element, extraOffset = 0) => {
    const position = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offset = position.top + scrollTop - extraOffset;

    window.scroll({
      top: offset,
      left: 0,
      behavior: 'smooth',
    });
  };
}

export default new Utilities();
