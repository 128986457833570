class Testimonials {
  constructor() {
    this.selectors = {
      wrapper: '.js-testimonials-wrapper',
      item: '.js-testimonial',
    };

    this.DOM = {
      wrappers: document.querySelectorAll(this.selectors.wrapper),
    };

    if (!this.DOM.wrappers) {
      return;
    }

    this.classes = {
      active: 'active',
    };

    this.setHeight();
    window.addEventListener('resize', () => this.setHeight());

    this.setIntervals();
  }

  setHeight() {
    this.DOM.wrappers.forEach((wrapper) => {
      const items = wrapper.querySelectorAll(this.selectors.item);
      if (items) {
        let max = 0;
        items.forEach((item) => {
          if (item.classList.contains(this.classes.active)) {
            const h = item.offsetHeight;
            max = h;
          }
          // const h = item.offsetHeight;
          // if (h > max) {
          //   max = h;
          // }
        });
        const wrapperEl = wrapper;
        wrapperEl.style.height = `${max}px`;
      }
    });
  }

  start(wrapper, items) {
    const wrapperEl = wrapper;
    const curr = wrapperEl.querySelector(`.${this.classes.active}`);
    if (curr) {
      let next = curr.nextElementSibling;
      if (!next) {
        // eslint-disable-next-line prefer-destructuring
        next = items[0];
      }
      curr.classList.remove(this.classes.active);
      next.classList.add(this.classes.active);
      this.setHeight();
    }
  }

  setIntervals() {
    this.DOM.wrappers.forEach((wrapper) => {
      const items = wrapper.querySelectorAll(this.selectors.item);
      setInterval(() => {
        this.start(wrapper, items);
      }, 8000);
    });
  }
}

export default new Testimonials();
