/*
  Project: Seeknow
  Author: Marcin Ziolek
 */

import './modules/MainNav';
import './modules/video';
import GLightbox from 'glightbox';
import StickyItems from './modules/StickyItems';
import './modules/testimonials';
import './modules/LoadMore';
import './modules/Drawers';
import './modules/Parallax';
import Swipers from './modules/Swipers';
import './modules/ContactFormScroll';
import './modules/Woocommerce';

GLightbox();
StickyItems();
Swipers();
