import inView from 'in-view';

class StickyItems {
  clearClasses = (dom) => {
    this.DOM[dom].forEach((l) => {
      l.classList.remove(this.classes.active);
    });
  };

  sectionInView = (el) => {
    const currLink = this.DOM.links[el.dataset.index];
    if (currLink) {
      this.clearClasses('links');
      currLink.classList.add(this.classes.active);
    }
  };

  constructor(items) {
    this.desktopBreakpoint = 768;
    this.inV = null;
    this.windowW = window.outerWidth;

    this.selectors = {
      link: '.js-sticky-link',
      section: '.js-sticky-section',
    };

    this.DOM = {
      links: items.querySelectorAll(this.selectors.link),
      sections: items.querySelectorAll(this.selectors.section),
    };

    if (!this.DOM.sections) {
      return;
    }

    this.classes = {
      active: 'active',
    };

    this.offset = 100;

    if (items.classList.contains('is-type-margin')) {
      this.offset = 200;

      if (window.innerHeight > 800) {
        this.offset = 250;
      }
    }

    this.linkClick();

    if (this.windowW >= this.desktopBreakpoint) {
      this.init();
    }

    window.addEventListener('resize', () => {
      this.windowW = window.outerWidth;
      this.inV = null;
      if (this.windowW >= this.desktopBreakpoint) {
        this.init();
      }
    });
  }

  init() {
    inView.offset(this.offset);
    this.inV = inView(this.selectors.section).on('enter', this.sectionInView);
  }

  linkClick() {
    this.DOM.links.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const l = event.target;
        const target = document.querySelector(l.hash);
        if (target) {
          if (this.windowW >= this.desktopBreakpoint) {
            target.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
          this.clearClasses('links');
          this.clearClasses('sections');
          l.classList.add(this.classes.active);
          target.classList.add(this.classes.active);
        }
      });
    });
  }
}

export default () => {
  const stickyItems = document.querySelectorAll('.js-sticky-items');

  if (stickyItems.length) {
    stickyItems.forEach((items) => new StickyItems(items));
  }
};
