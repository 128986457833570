class Swipers {
  constructor(swiper, Modules) {
    this.selectors = {
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      dots: '.swiper-dots',
      slides: '.swiper-wrapper',
      slide: '.swiper-slide',
    };

    this.video = {
      button: '.js-video-watch',
      video: '.c-embed',
      iframe: 'iframe',
      item: '.js-video-item',
    };

    this.classes = {
      active: 'is-active',
    };

    this.swiper = swiper;
    this.SwiperModules = Modules;
    this.slider = null;

    this.init();
  }

  getSetting(name) {
    return this.swiper.getAttribute(`data-swiper-${name}`);
  }

  init() {
    const { Swiper, Autoplay, Navigation, Pagination } = this.SwiperModules;

    const use = [];

    const items = this.getSetting('items') || 1;

    const args = {
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: items === '1' ? 0 : 10,
      watchSlidesProgress: true,
      loop: this.getSetting('loop') === 'yes', // loop to get next prev visible
      pagination: false,
      effect: 'slide',
      centeredSlides: this.getSetting('center') === 'yes',
      breakpoints: {
        640: {
          slidesPerView: 2.2,
          spaceBetween: 15,
        },
        860: {
          slidesPerView: 3.2,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 3.15,
          spaceBetween: 20,
        },
        1366: {
          slidesPerView: 3.275,
          spaceBetween: 25,
        },
      },
    };

    if (this.getSetting('autoplay') === 'yes') {
      use.push(Autoplay);
      args.autoplay = {
        delay: this.getSetting('autoplay-timeout') || 5000,
        disableOnInteraction: true,
      };
    }
    if (this.getSetting('breakpoint') === 'no') {
      args.breakpoints = {
        480: {
          slidesPerView: 1,
        },
      };
    }
    if (this.getSetting('dots') === 'yes') {
      use.push(Pagination);
      args.pagination = {
        el: this.swiper.parentElement.querySelector(this.selectors.dots),
        clickable: true,
      };
    }

    if (this.getSetting('arrows') === 'yes') {
      use.push(Navigation);
      args.navigation = {
        nextEl: this.selectors.nextButton,
        prevEl: this.selectors.prevButton,
      };
    }

    Swiper.use(use);
    this.slider = new Swiper(this.swiper, args);
  }

  videoReset() {
    const activeItem = this.swiper.querySelectorAll(
      `${this.video.item}.${this.classes.active}`,
    );

    if (activeItem.length) {
      activeItem.forEach((c) => {
        c.classList.remove(this.classes.active);
        const iframe = c.querySelector(this.video.iframe);
        const { src } = iframe;

        iframe.src = this.videoAutoplayUrl(src, 0);
      });
    }
  }

  videoAutoplayUrl(src, value) {
    const url = new URL(src);
    const params = url.searchParams;
    params.set('autoplay', value);
    if (
      this.getSetting('arrows') === 'yes' ||
      this.getSetting('dots') === 'yes'
    ) {
      params.set('controls', 0);
    }
    url.search = params.toString();

    return url.toString();
  }

  videoPlay(item) {
    const iframe = item.querySelector(this.video.iframe);
    const { src } = iframe;

    iframe.src = this.videoAutoplayUrl(src, 1);
  }

  videoToggle() {
    const buttons = document.querySelectorAll(this.video.button);

    if (buttons.length) {
      buttons.forEach((button) => {
        button.addEventListener('click', () => {
          this.videoReset();
          const item = button.closest(this.video.item);

          item.classList.add(this.classes.active);
          this.videoPlay(item);
        });
      });
    }
  }
}

export default () => {
  const swipers = document.querySelectorAll('.swiper');

  if (swipers.length) {
    const imports = [import('swiper')];
    imports.push(import('glightbox'));
    Promise.all(imports).then(
      ([
        { Swiper, Autoplay, Navigation, Pagination },
        { default: GLightbox },
      ]) => {
        swipers.forEach(
          (swiper) =>
            new Swipers(swiper, { Swiper, Autoplay, Navigation, Pagination }),
        );
        if (GLightbox) {
          GLightbox({
            slideEffect: 'slide', // slide, fade, zoom, none
            loop: false,
          });
        }
      },
    );
  }
};
