import Utilities from './Utilities';

class Drawers {
  constructor() {
    this.selectors = {
      section: '.js-drawers',
      button: '.js-drawer-button',
      content: '.js-drawer-content',
      item: '.js-drawer',
      isEqualHeight: '.is-equal-height',
      hasEqualHeight: '.has-equal-height',
    };

    this.sections = document.querySelectorAll(this.selectors.section);

    if (!this.sections.length) {
      return;
    }

    this.DOM = {
      buttons: document.querySelectorAll(this.selectors.button),
      equalHeightSections: document.querySelectorAll(
        `${this.selectors.section}${this.selectors.hasEqualHeight}`,
      ),
    };

    this.classes = {
      active: 'is-active',
    };

    this.init();
    this.equalHeight();

    Utilities.debounce(this.equalHeight.bind(this), 500);
  }

  equalHeight() {
    if (this.DOM.equalHeightSections.length) {
      this.DOM.equalHeightSections.forEach((section) => {
        const equalHeightElements = section.querySelectorAll(
          this.selectors.isEqualHeight,
        );

        equalHeightElements.forEach((el) => {
          const elem = el;
          elem.style.height = 'auto';
        });

        const maxHeight = Math.max(
          ...Array.from(equalHeightElements).map((elem) => elem.offsetHeight),
        );

        equalHeightElements.forEach((el) => {
          const elem = el;
          elem.style.height = `${maxHeight}px`;
        });
      });
    }
  }

  init() {
    this.DOM.buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const item = button.closest(this.selectors.item);
        const content = item.querySelector(this.selectors.content);

        const activeItem = document.querySelector(
          `${this.selectors.item}.${this.classes.active}`,
        );

        if (
          activeItem &&
          activeItem.getAttribute('id') !== item.getAttribute('id')
        ) {
          activeItem.querySelector(this.selectors.content).style.maxHeight =
            null;
          activeItem.classList.remove(this.classes.active);
          activeItem
            .querySelector(this.selectors.button)
            .setAttribute('aria-expanded', false);
        }

        if (item.classList.contains(this.classes.active)) {
          content.style.maxHeight = null;
          item.classList.remove(this.classes.active);
          button.setAttribute('aria-expanded', false);
        } else {
          content.style.maxHeight = `${content.scrollHeight}px`;
          item.classList.add(this.classes.active);
          button.setAttribute('aria-expanded', true);
        }
      });
    });
  }
}

export default new Drawers();
