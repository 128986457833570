import Utilities from './Utilities';

class Woocommerce {
  constructor() {
    this.emailCheckHandler();
    this.scrollToCart();
  }

  isEmailValid = (email = '') => {
    let emailValid = sessionStorage.getItem('accessStore');

    if (!emailValid) {
      emailValid =
        email.indexOf('@houselens.com') >= 0 ||
        email.indexOf('@laddernow.com') >= 0 ||
        email.indexOf('@seek-now.com') >= 0;
    }

    if (emailValid) {
      this.products.classList.add('is-active');
      this.emailCheck.classList.add('is-hidden');
      this.errorField.innerHTML = '';
      sessionStorage.setItem('accessStore', true);
      return true;
    }

    this.products.classList.remove('is-active');
    this.emailCheck.classList.remove('is-hidden');
    sessionStorage.removeItem('accessStore');

    return false;
  };

  emailCheckHandler() {
    this.emailCheck = document.querySelector('.js-email-check');
    this.products = document.querySelector('.js-store-hidden');

    if (!this.emailCheck || !this.products) {
      return;
    }

    this.emailCheckForm = this.emailCheck.querySelector('.js-email-check-form');
    this.emailInput = this.emailCheck.querySelector('#woocommerce-email');
    this.errorField = this.emailCheck.querySelector('.js-email-check-error');

    this.isEmailValid();

    this.emailCheckForm.addEventListener('submit', (e) => {
      e.preventDefault();

      const email = this.emailInput.value.toLowerCase();

      const valid = this.isEmailValid(email);

      if (!valid) {
        this.errorField.innerHTML = 'Sorry that Email is not in our System';
      }
    });
  }

  scrollToCart() {
    this.cart = document.querySelector('.js-cart-widget');

    if (!this.cart) {
      return;
    }

    this.addToCartButtons = document.querySelectorAll(
      '.add_to_cart_button,.single_add_to_cart_button',
    );

    if (this.addToCartButtons.length) {
      this.addToCartButtons.forEach((button) => {
        button.addEventListener('click', () => {
          setTimeout(() => {
            Utilities.smoothScrollToElement(this.cart, 20);
          }, 500);
        });
      });
    }
  }
}

export default new Woocommerce();
